import React from 'react'
import {motion} from 'framer-motion'
import ServiceLandingSection from '../../ServiceLandingSections/ServiceLandingSection';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { ArrowRightCircle } from 'react-bootstrap-icons';
import CompetitorAnalysis from '../../../assets/images/competitor-analysis.png';
import UserInterview from '../../../assets/images/userinterview.png';
import Survey from '../../../assets/images/surveys.png';
import UsabilityTesting from '../../../assets/images/usertesting.png';
import Analytics from '../../../assets/images/analytics.png';
import PersonaDevelopment from '../../../assets/images/personadevelopment.png';
import './nextsteps.css';

const UserResearch = () => {

    const service_usp = "UX research";
    const service_subtitle = "Improving user experience can lead to a 10x ROI."

  return (
    <motion.div className='marketing-container'>
        <ServiceLandingSection service_usp={service_usp} service_subtitle={service_subtitle} />

        <div className='user-research-services'>
            <h2 className='web-dev-approach-heading'>Our user research services</h2>
            <p className='marketing-services-subtitle'>Unlock valuable insights and enhance your product's user experience with our comprehensive user research services, tailored to better understand your customers.</p>
        </div>

        <Container className='marketing-services-grid'>
            <Row className='d-flex justify-content-center'>

                <Col md={4} xs={12} className='marketing-service-col'>
                    <Image src={UserInterview} width={40} height={40} fluid />
                    <h3 className='marketing-service-title'>User Interviews</h3>
                    <p className='marketing-service-desc'>Conduct in-depth interviews with users to gain insights into their needs.</p>
                </Col>

                <Col md={4} xs={12} className='marketing-service-col'>
                    <Image src={Survey} width={40} height={40} fluid />
                    <h3 className='marketing-service-title'>Surveys</h3>
                    <p className='marketing-service-desc'>Gather quantitative feedback from a wider audience to identify trends and preferences.</p>
                </Col>

                <Col md={4} xs={12} className='marketing-service-col'>
                    <Image src={CompetitorAnalysis} width={40} height={40} fluid />
                    <h3 className='marketing-service-title'>Competitor Analysis</h3>
                    <p className='marketing-service-desc'>Understand your competitors’ strengths and weaknesses to improve your strategies.</p>
                </Col>

            </Row>

            <Row className='mt-5 d-flex justify-content-center'>

                <Col md={4} xs={12} className='marketing-service-col'>
                    <Image src={UsabilityTesting} width={40} height={40} fluid />
                    <h3 className='marketing-service-title'>Usability Testing</h3>
                    <p className='marketing-service-desc'>Enhance your product's usability through real user testing and targeted improvements.</p>
                </Col>

                <Col md={4} xs={12} className='marketing-service-col'>
                    <Image src={Analytics} width={40} height={40} fluid />
                    <h3 className='marketing-service-title'>Analytics</h3>
                    <p className='marketing-service-desc'>Leverage data-driven insights to optimize user experiences and make informed decisions.</p>
                </Col>

                <Col md={4} xs={12} className='marketing-service-col'>
                    <Image src={PersonaDevelopment} width={40} height={40} fluid />
                    <h3 className='marketing-service-title'>Persona Development</h3>
                    <p className='marketing-service-desc'>Create detailed user personas for tailored design and marketing strategies.</p>
                </Col>

            </Row>            
        </Container>

        <div className='addvalue-container'>
        <div className='addvalue-content'>
          <h1 className='addvalue-title'>Services that <br />
            <span className='addvalue-usp'>add value</span> to your business</h1>

          <Row>
          <Col md={6} sm={12} className='mt-5'>
            <div className='addvalue-card addvalue-card1'>
              <h4 className='addvalue-card-title'>Graphic Design</h4>
              <h2 className='addvalue-card-question'>Ready to transform your user research findings into impactful design solutions?</h2>
              <p className='addvalue-card-desc'>From profile pictures to video thumbnails, ad graphics, and much more. Partner with our team of experts to design your social media according to your brand identity.</p>
              <ul className='addvalue-card-list'>
                <li>Social media posts</li>
                <li>Profile pictures</li>
                <li>Ad graphics</li>
              </ul>
              <b>... and much more</b>

              <div className='service-cta'>
                <a href="/design">Explore our services for design <ArrowRightCircle size={25} /></a>
              </div>
            </div>
          </Col>

          <Col md={6} sm={12} className='mt-5'>
            <div className='addvalue-card addvalue-card2'>
              <h4 className='addvalue-card-title'>Product Development</h4>
              <h2 className='addvalue-card-question'>Want to turn your insights into a full-fledged application?</h2>
              <p className='addvalue-card-desc'>
              Guided by the insights gained from extensive user research, 
              we craft intuitive, high-performance digital solutions that captivate your audience. 
              From conceptualization to implementation, we ensure that your vision 
              is not only realized but also exceeds expectations.
              </p>

              <div className='service-cta'>
                <a href="/development">More about product development <ArrowRightCircle size={25} /></a>
              </div>
            </div>
          </Col>
          </Row>
        </div>

      </div>

    </motion.div>
  )
}

export default UserResearch;
