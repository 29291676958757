import React, { useEffect, useState, useRef } from 'react';
import './Header.css';
import { useLocation } from 'react-router-dom';
import BrandSpaceLogo from '../../assets/images/final_brandspace_logo.png';
import { Navbar, Container, Nav, Image, NavDropdown } from 'react-bootstrap';

const Header = () => {

  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname.substring(1));
  const [scrolling, setScrolling] = useState(false);
  const navbarRef = useRef(null);
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };
 
  useEffect(() => {
	const onScroll = () => {
		const currentPosition = window.scrollY;
		if (currentPosition > 0) {
		  setScrolling(true);
		} else {
		  setScrolling(false);
		}
	  };

	window.addEventListener('scroll', onScroll);

	return () => window.removeEventListener("scroll", onScroll);
	
  }, []);

  useEffect(() => {
    // Use the pathname from location to update activeLink
    const pathname = location.pathname;
	if (["design", "website", "development", "ux-research", "marketing"].includes(pathname.substring(1))) {
		setActiveLink("services");
	} else if (pathname.substring(1) === "contact") {
		setActiveLink("contact");
	} else {
    setActiveLink(pathname.substring(1)); // Assuming your URLs are like "/home", "/services", etc.
	}
}, [location.pathname]);

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  }

   // Function to close the Navbar when scrolling on mobile
   const closeNavbarOnScroll = () => {
    if (window.innerWidth < 992) {
      // Close the Navbar toggle if it's open
      if (navbarRef.current && navbarRef.current.classList.contains('show')) {
        navbarRef.current.classList.remove('show');
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', closeNavbarOnScroll);

    return () => window.removeEventListener('scroll', closeNavbarOnScroll);
  }, []);


return (
	<Navbar expand="lg" 
		expanded={expanded} onToggle={handleToggle} 
		className={`${scrolling || activeLink === "services" || activeLink === "contact" || expanded ? "scrolling" : ""}`}>
		<Container>
			<Navbar.Brand href="/home">
				<Image src={BrandSpaceLogo} alt="Logo" width={92} height={100} />
			</Navbar.Brand>
			<Navbar.Toggle aria-controls="basic-navbar-nav">
				<span className='navbar-toggler-icon'></span>
			</Navbar.Toggle>
			<Navbar.Collapse ref={navbarRef} id="basic-navbar-nav">
			<Nav className="ms-auto">
				<Nav.Link href="/home" 
				className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'}
				onClick={() => onUpdateActiveLink('home')}>
				Home
				</Nav.Link>

				{/* Use NavDropdown for the Services dropdown */}
				<NavDropdown title="Services" id="basic-nav-dropdown">
				{/* Add dropdown items for each service */}
					<NavDropdown.Item href="/design">Design</NavDropdown.Item>
					<NavDropdown.Item href="/website">Website Development</NavDropdown.Item>
					<NavDropdown.Item href="/development">Product Development</NavDropdown.Item>
					<NavDropdown.Item href="/ux-research">User Research</NavDropdown.Item>
					<NavDropdown.Item href="/marketing">Marketing</NavDropdown.Item>
				{/* Add more items as needed */}
				</NavDropdown>

				<Nav.Link href="/home#about" 
				className={activeLink === 'about' ? 'active navbar-link' : 'navbar-link'}
				onClick={() => onUpdateActiveLink('about')}>

				About

				</Nav.Link>

				{/* <Nav.Link href="/blog" 
				className={activeLink === 'blog' ? 'active navbar-link' : 'navbar-link'}
				onClick={() => onUpdateActiveLink('blog')}>

				Blog

				</Nav.Link> */}
			</Nav>
				<span className='navbar-text'>
					<a href="/contact">
						<button>
							<span><em>Work With Us</em></span>
						</button>
					</a>
				</span>
			</Navbar.Collapse>
		</Container>
</Navbar>
);

}

export default Header;