import React from 'react';
import {ArrowRightCircle} from 'react-bootstrap-icons';
import { Container } from 'react-bootstrap';
import './ServiceLandingSection.css';

const ServiceLandingSection = ({ service_usp, service_subtitle }) => {
  return (
      <Container className='d-flex justify-content-center align-items-center service-container'>
        <div className='service-landing-content'>
          <h1 className='service-landing-title'>Grow your business with <br />
            <span className='service-usp'>{service_usp}</span> that works for you</h1>
          <div className='service-subtitle'>{service_subtitle}</div>
          <div className='service-cta'>
            <a href="/contact">Schedule a call <ArrowRightCircle size={25} /></a>
          </div>
        </div>
      </Container>
  )
}

export default ServiceLandingSection;