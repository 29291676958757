import React from 'react';
import { Outlet } from 'react-router-dom';
import './landingpage.css';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';


const LandingPage = () => {

  return (
    <div className="navbar-wrapper">
        <div>
          <Header />
        </div>
        <div className="the-rest-of-the-page">
            <Outlet />
        </div>
        <Footer />
    </div>
  )
}

export default LandingPage
