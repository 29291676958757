import React from 'react';
import './footer.css';
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';

const Footer = () => {
  return (
    <div className='footer'>
      <div className='sb__footer section__padding'>
        <div className='sb__footer-links'>
          <div className='sb__footer-links-div'>
            <h4>Services</h4>
            <a href="/design">
              <p>Design</p>
            </a>
            <a href="/website">
              <p>Website Development</p>
            </a>
            <a href="/development">
              <p>Product Development</p>
            </a>
            <a href="/ux-research">
              <p>UX Research</p>
            </a>
            <a href="/marketing">
              <p>Marketing</p>
            </a>
          </div>

          <div className='sb__footer-links-div'>
            <h4>Company</h4>
            <a href="/home#about">
              <p>About</p>
            </a>
            <a href="/contact">
              <p>Contact Us</p>
            </a>
          </div>

          <div className='sb__footer-links-div'>
            <h4>Connect with us on</h4>
            <div className='socialmedia'>
              <a href="https://www.facebook.com/getbrandspace" rel="noreferrer" target="_blank"><FaFacebook /></a>
              <a href="https://www.instagram.com/brandspacetoronto/" rel="noreferrer" target="_blank"><FaInstagram /></a>
              <a href="https://www.linkedin.com/company/get-brand-space/" rel="noreferrer" target="_blank"><FaLinkedin /></a>
            </div>
          </div>
        </div>

      <hr></hr>

      <div className='sb__footer-below'>
        <div className='sb__footer-copyright'>
          <p>
            &copy; {new Date().getFullYear()} BrandSpace. All rights reserved.
          </p>
        </div>
      </div>

      </div>
    </div>
  );
}

export default Footer;