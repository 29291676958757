import {React, useEffect, useState} from 'react'
import CalendlyComponent from '../../components/CalendlyComponent/CalendlyComponent';
import './homepage.css';
import ServiceCard from '../../components/ServiceCard/ServiceCard';
import Icon1 from '../../assets/images/stars.png';
import Icon2 from '../../assets/images/stars2.png';
import NextLevelPic from '../../assets/images/nextlevel.png';
import {motion} from 'framer-motion';
import { Col, Container, Row, Image } from 'react-bootstrap';
import Banner from '../../components/Banner/Banner';
import GraphicDesignIcon from '../../assets/images/graphic-designicon.png';
import RocketIcon from '../../assets/images/rocket.png';
import WebDevIcon from '../../assets/images/webdev_icon.png';
import ProductDevIcon from '../../assets/images/productdevicon.png';
import UserResearchIcon from '../../assets/images/user_research_icon.png';


const HomePage = () => {

  const bullet_points = {
    points1: ['Website Design', 'Product (UI/UX) Design', 'Graphic Design', 'Branding'],
    points2: ['Brand Website', 'Content Management System (CMS)', 'Search Engine Optimization (SEO)', 'eCommerce'],
    points3: ['Cloud-based Web Applications', 'Mobile Applications', 'Desktop Applications'],
    points4: ['Competitor Analysis', 'User Testing', 'Analytics & Reports'],
    points5: ['Social Media Management', 'Pay-Per-Click (PPC)', 'Email Campaigns', 'Content Marketing'],
  }

  const staricons = {
    icon1: Icon1,
    icon2: Icon2,
    icon3: Icon1,
    icon4: Icon2,
    icon5: Icon1,
  }

  const mainicons = {
    icon1: GraphicDesignIcon,
    icon2: WebDevIcon,
    icon3: ProductDevIcon,
    icon4: UserResearchIcon,
    icon5: RocketIcon,
  }

const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
      const handleResize = () => {
          setScreenWidth(window.innerWidth);
      };

      window.addEventListener('resize', handleResize);

      // Clean up the event listener when the component is unmounted
      return () => {
          window.removeEventListener('resize', handleResize);
      };
  }, []);
  const isMobile = screenWidth <= 768;

  useEffect(() => {
    if (window.location.hash === "#contact") {
        const scrollToContactUs = () => {
          const element = document.getElementById("contact");
          if (element) {
            element.scrollIntoView({ behavior: "smooth" });
          } else {
            // If the element isn't available yet, try again after a short delay
            setTimeout(scrollToContactUs, 1000);
          }
        };
        scrollToContactUs();
    }

    if (window.location.hash === "#about") {
      const scrollToAbout = () => {
        const element = document.getElementById("about");
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        } else {
          // If the element isn't available yet, try again after a short delay
          setTimeout(scrollToAbout, 1000);
        }
      };
      scrollToAbout();
  }

  }, []);

  return (
    <>
    <Banner />
    <section id="services" className='home-services'>
      <Container>
        <Row className='align-items-center'>
          <Col lg={12}>
            <div className='section-title text-center'>
              <div className='section-sub-title'>
                <h3>OUR SERVICES</h3>
              </div>
              <div className='section-main-title'>
                <h1>What We Offer</h1>
              </div>
              <div className='section-description'>
                <p>
                  Elevating businesses with holistic branding 
                  strategies that enhance their digital identity 
                  and impact
                </p>
              </div>
            </div>
          </Col>
        </Row>

    <div>
      <Row>

          <ServiceCard
            title={"Design"}
            bullet_points={bullet_points["points1"]}
            mainicon={mainicons["icon1"]}
            starsicon={staricons["icon1"]}
            location={"/design"}
          />

          <ServiceCard
            title={"Website Development"}
            bullet_points={bullet_points["points2"]}
            mainicon={mainicons["icon2"]}
            starsicon={staricons["icon2"]}
            location={"/website"}
          />

          <ServiceCard
            title={"Product Development"}
            bullet_points={bullet_points["points3"]}
            mainicon={mainicons["icon3"]}
            starsicon={staricons["icon3"]}
            location={"/development"}
          />

      </Row>

      <Row>
        <Col md={2}></Col>
        
        <ServiceCard
            title={"User Research"}
            bullet_points={bullet_points["points4"]}
            mainicon={mainicons["icon4"]}
            starsicon={staricons["icon4"]}
            location={"/ux-research"}
        />

        <ServiceCard
            title={"Marketing"}
            bullet_points={bullet_points["points5"]}
            mainicon={mainicons["icon5"]}
            starsicon={staricons["icon5"]}
            location={"/marketing"}
        />

        <Col md={2}></Col>

        
      </Row>
    </div>

    </Container>
      
    </section>

    <section id="about" className='home-about'>
      <Container>
        <Row className='align-items-center'>
          <Col lg={12}>
            <div className='section-title text-center'>
              <div className='section-sub-title'>
                <h3>ABOUT US</h3>
              </div>
              <div className='section-main-title'>
                <h1>Value Proposition</h1>
              </div>
            </div>
          </Col>
          <Col lg={6} xl={6} sm={12}>
            <Image className='about-picture img-fluid' src={NextLevelPic} />
          </Col>
          <Col lg={4} xl={6} sm={12} className="home-about-section">
            <h3 className='about-title'>Take Your Brand to the <br /> Next Level</h3>

            <h4 className='my-3 about-subtitle'>
              <strong>Are you frustrated with stagnant growth and missed opportunities?</strong>
            </h4>

            <p>Look no further. At Brand Space&reg;, we take care of all your branding needs.</p>

            <p>From designing your initial graphics, all the way to running full-fledged Ad campaigns; we do it all, with precision
              and perfection. While we offer a full set of digital services, our true specialty lies
              in crafting your unique business identity in the digital world.
            </p>

            <a href="#contact">Get Started</a>
          </Col>
        </Row>
      </Container>
    </section>

    <section id="contact" className='home-contactus'>
      <Container>
        <Row className='align-items-center'>
          
          {isMobile ? (
              <Row lg={12} style={{display:"flex", justifyContent:"center", alignItems:"center", margin:"0"}}>
                <div className='section-title text-center'>
                  <div className='section-sub-title'>
                    <h3>WORK WITH US</h3>
                  </div>
                  <div >
                    <p style={{color: "646464", fontWeight:"500"}}>
                      After you book a call with us, you will get a Welcome email and a questionnaire within it. Feel free to fill it out anytime before the meeting.
                    </p>
                  </div>
                </div>
                </Row>
          ) : (
            <Col lg={12}>
            <div className='section-title text-center'>
            <div className='section-sub-title'>
              <h3>WORK WITH US</h3>
            </div>
            <div className='section-description'>
              <p>
                After you book a call with us, you will get a Welcome email and a questionnaire within it. Feel free to fill it out anytime before the meeting.
              </p>
            </div>
          </div>
          </Col>
          )}
          <Row>
            <CalendlyComponent />
          </Row>
        </Row>
      </Container>
    </section>
    </>
  

  )
}

export default HomePage;

