import { createContext } from 'react';

const AuthContext = createContext();

export default AuthContext;


export const AuthProvider = ({children}) => { 

    // all global variables need to be here 

    let contextData = {

    }


    return (
        <AuthContext.Provider value={contextData}>
            {children}
        </AuthContext.Provider>
    )
}

