import React, {useState} from 'react'
import {motion} from 'framer-motion'
import ServiceLandingSection from '../../ServiceLandingSections/ServiceLandingSection';
import { Container, Row, Col, Tab, Nav, Image } from 'react-bootstrap';
import './ProductDevelopment.css';
import MobileApp from '../../../assets/images/mobileapp.svg';
import WebApp from '../../../assets/images/webapp.png';
import DesktopApp from '../../../assets/images/desktopapp.png';
import Shield from '../../../assets/images/shield.png';
import Rocket from '../../../assets/images/rocket_guarantee.png';
import Warranty from '../../../assets/images/warranty.png';
import { Lightbulb, Brush, Code, Globe2, ArrowRightCircle } from 'react-bootstrap-icons';

const ProductDevelopment = () => {
    const [activeTab, setActiveTab] = useState('ideation');

    const service_usp = "product development";
    const service_subtitle = "Transform your ideas into exceptional products."

  return (
    <motion.div className='product-dev-container'>
        <ServiceLandingSection service_usp={service_usp} service_subtitle={service_subtitle} />

        <div className='product-dev-products'>
            <Row>
                <Col md={4} sm={12}>
                    <Image fluid src={WebApp} />
                    <h3>Web Applications</h3>
                </Col>
                <Col md={4} sm={12}>
                    <Image fluid src={MobileApp} />
                    <h3>Mobile Applications</h3>
                </Col>
                <Col md={4} sm={12}>
                    <Image fluid src={DesktopApp} />
                    <h3>Desktop Applications</h3>
                </Col>
            </Row>
        </div>

        <div className='product-dev-process'>
            <h2 className='web-dev-approach-heading'>Our process</h2>
            <p className='web-dev-approach-description'>Cloud-based web apps, SaaS products, or mobile apps, we'll do it all, with precision and perfection.</p>
        </div>

    <div className='w-100'>
    <Container className="mt-5 tab-container">
      <Row>
        <Col md={12} className="mx-auto">
          <Tab.Container activeKey={activeTab} onSelect={(key) => setActiveTab(key)}>
            <div className="card">
              <div className="card-header">
                <Nav variant="tabs" className="justify-content-center">
                  <Nav.Item>
                    <Nav.Link eventKey="ideation" className='custom-nav-link'>
                     <Lightbulb size={25} /> Ideation
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="design" className='custom-nav-link'>
                     <Brush size={25} /> Design
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="development">
                     <Code size={25} /> Development
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="deployment">
                     <Globe2 size={25} /> Go Live
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
              <div className="card-body">
                <Tab.Content>
                  <Tab.Pane eventKey="ideation">

                    <div className="tab-card-content">
                        <h1>Turn your <span className='green-color'>ideas</span> into 
                                <span className='purple-color'> reality</span>.</h1>
                        <br />
                        <p>At <b>Brand Space</b>, we believe that every great product begins with a spark of inspiration. Our ideation process is where your vision takes shape and transforms into a tangible reality.</p>

                        <h3>Collaborative Ideation</h3>
                        <p>We start by listening to you, understanding your vision, and exploring your ideas. Through collaborative brainstorming sessions, we nurture and refine your concepts to ensure they align perfectly with your goals and objectives.</p>

                        <h3>Competitor Analysis</h3>
                        <p>In the ever-evolving digital landscape, a thorough understanding of your industry is paramount. Our experts delve deep into your market, conducting extensive research and competitor analysis. This process provides invaluable insights into market trends, customer preferences, and potential gaps that we can leverage for your success.</p>

                        <h3>Defining Your Product Strategy</h3>
                        <p>With a comprehensive understanding of the market, we meticulously craft a tailored product strategy that sets you apart. We define your unique value proposition, pinpoint your target audience, and create a roadmap to guide us toward achieving your objectives.</p>
                    </div>


                  </Tab.Pane>

                  <Tab.Pane eventKey="design">
                    <div className="tab-card-content">
                        <h1>Bring your <span className='gradient-color'>design</span> to life.</h1>
                        <br />

                        <p>We follow a very iterative design process that covers everything, from identifying brand colors and fonts, all the way to interactive mockups, all the while ensuring it meets your expectations and your vision.</p>

                        <h3>Wireframing & Prototyping</h3>
                        <p>Our design process starts here. Wireframes create a visual structure, guiding intuitive user experiences. Prototyping brings your vision to life with interactive mockups, enabling early feedback for a product aligned with your vision and user needs.</p>

                        <h3>Responsive Design</h3>
                        <p>We ensure your project looks and works flawlessly across desktops, tablets, and smartphones. Our responsive approach guarantees seamless experiences, adapting to various screen sizes.</p>

                        <h3>UI/UX Design</h3>
                        <p>User Interface (UI) and User Experience (UX) are our design foundation. We craft visually appealing, user-friendly interfaces that guide users effortlessly. Beyond aesthetics, we focus on enhancing the user journey with intuitive navigation and meaningful interactions. Our UI/UX principles ensure every detail, from pixel to interaction, exceeds user expectations, fostering loyalty and engagement.</p>
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="development">
                    <div className="tab-card-content">
                        <h1>Craft <span className='green-color'>digital solutions</span> from vision.</h1>
                        <br />

                        <h3>Frontend & Backend Development</h3>
                        <p>At <b>Brand Space</b>, our experts excel in both frontend and backend development. We create intuitive UIs that captivate users, while our backend developers craft robust, scalable server-side solutions. From aesthetics to architecture, we ensure your digital product looks fantastic and functions flawlessly.</p>

                        <h3>Scalability & Security</h3>
                        <p>We understand that your digital presence needs room to grow and evolve as your business expands. We build your project on a secure foundation that can handle increased loads and demands without compromising performance, ensuring that your success is unhindered by growth.</p>

                        <h3>Testing & Quality Assurance</h3>
                        <p>By adhering to industry best practices, conducting thorough testing, and utilizing cutting-edge tools, we ensure that your project is not only bug-free but also a reliable and secure asset that meets the highest standards of quality.</p>
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="deployment">

                    <div className="tab-card-content">
                        <h1>Take your idea <span className='purple-color'>global</span>.</h1>
                        <br />

                        <h3>Efficient Deployment</h3>
                        <p>Our deployment process is designed for efficiency, ensuring your project goes live seamlessly. We leverage <b>AWS</b> and <b>Fastly</b> for reliable hosting, guaranteeing optimal performance and speed.</p>

                        <h3>Post-Launch Support</h3>
                        <p>We don't stop at launch—our support extends to post-launch maintenance, where we monitor, fine-tune, and address any issues promptly. Your digital journey with us is marked by smooth transitions and continued success.</p>

                    </div>
                  </Tab.Pane>

                </Tab.Content>
              </div>
            </div>
          </Tab.Container>
        </Col>
      </Row>
    </Container>
    </div>

    <div className='product-dev-guarantee'>
        <h2 className='web-dev-approach-heading'>Our guarantees</h2>

        <Row>
            <Col md={3} sm={12}>
                <div className='guarantee-card'>
                    <div className='guarantee-card-content'>

                        <div className="service-icon-thumb">
                            <Image src={Shield} width={112} height={112} alt='guarantee' fluid />
                        </div>

                        <div className='guarantee-card-title'>
                            <h3>Secure IP</h3>
                        </div>

                        <div className='guarantee-card-description'>
                            <p>Protecting your intellectual property and assets is our top priority.</p>
                        </div>

                    </div>
                </div>
            </Col>
            <Col md={3} sm={12}>
                <div className='guarantee-card'>
                    <div className='guarantee-card-content'>

                        <div className="service-icon-thumb">
                            <Image src={Rocket} width={112} height={112} alt='guarantee' fluid />
                        </div>

                        <div className='guarantee-card-title'>
                            <h3>Top Performance</h3>
                        </div>

                        <div className='guarantee-card-description'>
                            <p>Fast, seamless interactions is our primary goal.</p>
                        </div>

                    </div>
                </div>
            </Col>
            <Col md={3} sm={12}>

                <div className='guarantee-card'>
                    <div className='guarantee-card-content'>

                        <div className="service-icon-thumb">
                            <Image src={Warranty} width={112} height={112} alt='guarantee' fluid />
                        </div>

                        <div className='guarantee-card-title'>
                            <h3>Warranty</h3>
                        </div>

                        <div className='guarantee-card-description'>
                            <p>We provide you with peace of mind through our comprehensive 3-month bug-free guarantee.</p>
                        </div>

                    </div>
                </div>

            </Col>
        </Row>
    </div>

    <div className='addvalue-container'>
        <div className='addvalue-content'>
          <h1 className='addvalue-title'>Services that <br />
            <span className='addvalue-usp'>add value</span> to your website</h1>

          <Row>
          <Col md={6} sm={12} className='mt-5'>
            <div className='addvalue-card addvalue-card1'>
              <h4 className='addvalue-card-title'>Graphics Design</h4>
              <h2 className='addvalue-card-question'>Do your social media graphics reflect your brand consistently?</h2>
              <p className='addvalue-card-desc'>From profile pictures to video thumbnails, ad graphics, and much more. Partner with our team of experts to design your social media according to your brand identity.</p>
              <ul className='addvalue-card-list'>
                <li>Social media posts</li>
                <li>Profile pictures</li>
                <li>Ad graphics</li>
              </ul>
              <b>... and much more</b>

              <div className='service-cta'>
                <a href="/design">Explore our services for graphic design <ArrowRightCircle size={25} /></a>
              </div>
            </div>
          </Col>

          <Col md={6} sm={12} className='mt-5'>
            <div className='addvalue-card addvalue-card2'>
              <h4 className='addvalue-card-title'>Content Marketing</h4>
              <h2 className='addvalue-card-question'>Do you struggle to create consistent and valuable content?</h2>
              <p className='addvalue-card-desc'>
                Effective content planning lays the foundation for creating valuable and engaging content
                that resonates with your target audience, while strategic content promotion ensures that 
                your carefully crafted content reaches the widest and most receptive audience possible, 
                driving traffic, engagement, and conversions.
              </p>

              <div className='service-cta'>
                <a href="/marketing">More about our content marketing <ArrowRightCircle size={25} /></a>
              </div>
            </div>
          </Col>
          </Row>
        </div>

      </div>

    </motion.div>
  )
}

export default ProductDevelopment;


